import React, { useContext, useEffect, useState } from 'react'
import { LocalizedLink as Link, useLocalization } from '../../plugins/gatsby-theme-i18n-custom'
import Image from './Image'
import DE from '../svgs/flags-iso/DE.svg'
import GB from '../svgs/flags-iso/GB.svg'

import { Button, Offcanvas } from 'reactstrap'

import stickyNavbar from '../assets/js/src/components/sticky-navbar'
import useThemeModeSwitch from '../assets/js/src/components/theme-mode-switch'

import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import LayoutContext from './LayoutContext'

const Header = props => {
    const {
        // className = "",
        // fixed = true,
        // minimal = false,
        dark = false,
    } = props

    const [respNavbarOpen, setRespNavbarOpen] = useState(false)

    const mode = useThemeModeSwitch()

    useEffect(() => {
        stickyNavbar()
    }, [])

    function toggleRespNavbar() {
        setRespNavbarOpen(!respNavbarOpen)
    }

    const { t } = useTranslation()
    const { locale, config } = useLocalization()
    const { pathname } = useLocation()
    const path = pathname.replace(`/${locale}/`, `/`)

    const [layout, setLayout] = useContext(LayoutContext)

    const Menu = ({ mobile = false }) => (
        <>
            <div className={`offcanvas-header border-bottom ${dark ? 'border-light' : ''}`}>
                <h5 className={`offcanvas-title ${dark ? 'text-white' : ''}`}>{t('Menü')}</h5>
                <Button
                    color="close"
                    className={dark ? 'btn-close-white' : 'btn-close'}
                    onClick={toggleRespNavbar}
                ></Button>
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link
                            to="/"
                            className="nav-link"
                            onClick={mobile ? toggleRespNavbar : null}
                        >
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/investor"
                            className="nav-link"
                            onClick={mobile ? toggleRespNavbar : null}
                        >
                            {t('Anleger')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/business"
                            className="nav-link"
                            onClick={mobile ? toggleRespNavbar : null}
                        >
                            {t('Unternehmen')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/partner"
                            className="nav-link"
                            onClick={mobile ? toggleRespNavbar : null}
                        >
                            {t('Partner')}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/magazine"
                            className="nav-link"
                            onClick={mobile ? toggleRespNavbar : null}
                        >
                            {t('Magazin')}
                        </Link>
                    </li>
                    <li
                        className={`nav-item ms-lg-auto ${
                            !layout.disableLanguageSwitcher ? 'dropdown' : ''
                        }`}
                    >
                        <Link
                            language={locale === 'de' ? 'en' : 'de'}
                            to={path}
                            className={`nav-link dropdown-toggle show ${
                                layout.disableLanguageSwitcher ? 'btn btn-link disabled' : ''
                            }`}
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                        >
                            {locale === 'de' ? (
                                <DE
                                    height="28px"
                                    width="28px"
                                    viewBox="0 0 640 480"
                                    className="rounded"
                                />
                            ) : (
                                <GB
                                    height="28px"
                                    width="28px"
                                    viewBox="0 0 640 480"
                                    className="rounded"
                                />
                            )}
                        </Link>
                        <ul className="dropdown-menu show" data-bs-popper="static">
                            <li>
                                <Link language="de" to={path} className="dropdown-item">
                                    Deutsch
                                </Link>
                            </li>
                            <li>
                                <Link language="en" to={path} className="dropdown-item">
                                    English
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className={`offcanvas-header border-top ${dark ? 'border-light' : ''}`}>
                <a
                    href="https://go.bitalo.com/meetings/martin-albert"
                    className="btn btn-primary w-100"
                    target="_blank"
                    rel="noopener"
                >
                    <i className="bx bx-phone fs-4 lh-1 me-1"></i>
                    &nbsp;{t('Sprechen Sie mit uns')}
                </a>
            </div>
        </>
    )

    return (
        <>
            {/*Remove "navbar-sticky" class to make navigation bar scrollable with the page*/}
            <header
                className={`header navbar navbar-expand-lg position-absolute navbar-sticky ${
                    dark ? 'navbar-dark' : ''
                }`}
            >
                <div className="container px-3">
                    <Link to="/" className="navbar-brand pe-3">
                        {dark || mode === 'dark' ? (
                            <Image
                                src="bitalo_w.png"
                                loadFrom="logo"
                                loading="eager"
                                alt="Bitalo AG"
                                style={{ width: '160px', height: 'auto' }}
                            />
                        ) : (
                            <Image
                                src="bitalo.png"
                                loadFrom="logo"
                                loading="eager"
                                alt="Bitalo AG"
                                style={{ width: '160px', height: 'auto' }}
                            />
                        )}
                    </Link>
                    {!respNavbarOpen && (
                        <div className={`offcanvas ${dark ? 'navbar-dark' : ''}`}>
                            <Menu />
                        </div>
                    )}
                    <Offcanvas
                        toggle={toggleRespNavbar}
                        isOpen={respNavbarOpen}
                        direction="end"
                        className={dark ? 'bg-dark' : ''}
                    >
                        <Menu mobile={true} />
                    </Offcanvas>
                    <div className="dark-mode ms-auto me-4">
                        <div className="form-check form-switch mode-switch" data-bs-toggle="mode">
                            <input type="checkbox" className="form-check-input" id="theme-mode" />
                            <label
                                className="form-check-label d-none d-sm-block"
                                htmlFor="theme-mode"
                            ></label>
                            <label
                                className="form-check-label d-none d-sm-block"
                                htmlFor="theme-mode"
                            ></label>
                        </div>
                    </div>
                    <Button color="link" className="navbar-toggler" onClick={toggleRespNavbar}>
                        <span className="navbar-toggler-icon"></span>
                    </Button>
                    <a
                        href="https://go.bitalo.com/meetings/martin-albert"
                        className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
                        target="_blank"
                        rel="noopener"
                    >
                        <i className="bx bx-phone fs-5 lh-1 me-1"></i>
                        &nbsp;{t('Sprechen Sie mit uns')}
                    </a>
                </div>
            </header>
        </>
    )
}

export default Header
