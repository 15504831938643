import * as React from 'react'
import { LocaleProvider } from './context'
import { SEO } from './components/seo'
import { I18nextProvider } from 'react-i18next'
import { LayoutProvider } from '../../../src/components/LayoutContext'

// custom TOBI
const wrapPageElement = ({ element, props }) => {
    return (
        <LocaleProvider pageContext={props.pageContext}>
            <SEO location={props.location} pageContext={props.pageContext} />
            <I18nextProvider i18n={element.props.i18n}>
                <LayoutProvider {...props}>{element}</LayoutProvider>
            </I18nextProvider>
        </LocaleProvider>
    )
}

export { wrapPageElement }
