import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// formats: [AUTO, WEBP, AVIF]
const LoadGatsbyOptimizedImage = () =>
    useStaticQuery(graphql`
        {
            logo: allFile(
                filter: {
                    sourceInstanceName: { eq: "images" }
                    relativeDirectory: { in: "logos" }
                    ext: { ne: ".ini" }
                }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                                quality: 80
                                width: 500
                                layout: CONSTRAINED
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
            custom: allFile(
                filter: {
                    sourceInstanceName: { eq: "images" }
                    relativePath: { glob: "custom/**" }
                    ext: { ne: ".ini" }
                }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                                quality: 80
                                layout: FULL_WIDTH
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
        }
    `)

export default LoadGatsbyOptimizedImage
