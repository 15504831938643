/**
 * Theme Mode Switch
 * Switch betwen light/dark mode. The chosen mode is saved to browser's local storage
 */
import { useEffect, useState } from 'react'

const useThemeModeSwitch = () => {
    const [mode, setMode] = useState(null)

    useEffect(() => {
        const themeModeSwitch = () => {
            let modeSwitch = document.querySelector('[data-bs-toggle="mode"]')

            if (modeSwitch === null) return

            let checkbox = modeSwitch.querySelector('.form-check-input')

            let storedMode = window.localStorage.getItem('mode'),
                root = document.getElementsByTagName('html')[0]

            const setModeValue = mode => {
                if (mode === 'dark') {
                    root.classList.add('dark-mode')
                    checkbox.checked = true
                } else {
                    root.classList.remove('dark-mode')
                    checkbox.checked = false
                }
            }

            if (storedMode !== null && storedMode === 'dark') {
                setMode(storedMode)
                setModeValue(storedMode)
            }

            modeSwitch.addEventListener('click', e => {
                if (checkbox.checked) {
                    const newMode = 'dark'
                    setMode(newMode)
                    setModeValue(newMode)
                    window.localStorage.setItem('mode', newMode)
                } else {
                    const newMode = 'light'
                    setMode(newMode)
                    setModeValue(newMode)
                    window.localStorage.setItem('mode', newMode)
                }
            })
        }

        themeModeSwitch()
    }, [])

    return mode
}

export default useThemeModeSwitch
