import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import LoadGatsbyOptimizedImage from "./LoadGatsbyOptimizedImage"

const Image = ({
  src,
  loadFrom = "custom",
  className = "",
  alt = "",
  style = {},
  imgStyle,
  loading = "lazy",
}) => {
  const images = LoadGatsbyOptimizedImage()

  let img = images[loadFrom].edges.find(
    ({ node }) =>
      node.childImageSharp &&
      node.childImageSharp.gatsbyImageData.images.fallback.src
        .split("/")
        .pop() === src.split("/").pop()
  )

  if (!img) {
    console.warn(`<Image /> cannot find ${src}`)
    return <i>Bild nicht gefunden: {src}</i>
  }

  if (style.height && !style.width) {
    const aspectRatio =
      img.node.childImageSharp.gatsbyImageData.width /
      img.node.childImageSharp.gatsbyImageData.height
    // keep aspect ratio:
    style.width = style.height.replace("px", "") * aspectRatio + "px"
  }

  return (
    <GatsbyImage
      image={img.node.childImageSharp.gatsbyImageData}
      loading={loading}
      alt={alt}
      className={className}
      style={style}
      imgStyle={imgStyle}
    />
  )
}

export default Image
