exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-campaign-index-js": () => import("./../../../src/pages/campaign/index.js" /* webpackChunkName: "component---src-pages-campaign-index-js" */),
  "component---src-pages-complaint-js": () => import("./../../../src/pages/complaint.js" /* webpackChunkName: "component---src-pages-complaint-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-js": () => import("./../../../src/pages/investor.js" /* webpackChunkName: "component---src-pages-investor-js" */),
  "component---src-pages-magazine-index-js": () => import("./../../../src/pages/magazine/index.js" /* webpackChunkName: "component---src-pages-magazine-index-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-campaign-post-js": () => import("./../../../src/templates/campaignPost.js" /* webpackChunkName: "component---src-templates-campaign-post-js" */),
  "component---src-templates-magazine-post-js": () => import("./../../../src/templates/magazinePost.js" /* webpackChunkName: "component---src-templates-magazine-post-js" */)
}

