import React from 'react'
import { Helmet } from 'react-helmet'
import { Script } from 'gatsby'

const HsAnalytics = () => {
    return (
        <Helmet>
            <Script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src="//js-eu1.hs-scripts.com/139563833.js"
            />
        </Helmet>
    )
}

export default HsAnalytics
