import React from 'react'
import { Helmet } from 'react-helmet'

const GTM_SRC = `https://www.googletagmanager.com/gtm.js`
const GTM_ID = process.env.GATSBY_GTM_ID

export function GTMOnRouteUpdate(location) {
    let path = location ? `${location.pathname}${location.search}${location.hash}` : ''

    // console.log("GTMOnRouteUpdate")
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'page_view_custom',
        page_path: path,
        referrer: document.referrer,
    })
}

export function sendConversion(name) {
    window.dataLayer.push({ event: name })
}

// latest gtm implementation 23.2.22 (Tobias)
const GTM = ({ location }) => {
    function handleGTagOnLoad() {
        // console.log("gtm onload") //todo debug
        window.dataLayer = window.dataLayer || []

        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        })

        window.dataLayer.push({ event: 'load_optimize' }) // todo: custom optimize loading (not always)

        GTMOnRouteUpdate(location)
    }

    function handleScriptInject({ scriptTags }) {
        if (scriptTags) {
            scriptTags.forEach(script => {
                if (script.src && script.src.includes(GTM_SRC)) {
                    script.onload = handleGTagOnLoad
                }
            })
        }
    }

    return (
        <>
            <Helmet onChangeClientState={(newState, addedTags) => handleScriptInject(addedTags)}>
                <script src={`${GTM_SRC}?id=${GTM_ID}`} />
            </Helmet>
        </>
    )
}

export default GTM
