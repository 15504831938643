/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import ReactDOM from 'react-dom/client'

import { GTMOnRouteUpdate } from './src/components/GTM'
import { wrapPageElement as wrapPageElementLang } from './plugins/gatsby-theme-i18n-custom/src/wrap-page-element'

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}

export const wrapPageElement = ({ element, props }) => {
    return wrapPageElementLang({ element, props })
}

// https://github.com/andrezimpel/gatsby-plugin-gdpr-cookies/blob/master/src/gatsby-browser.js
// runs also after page init
export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log("onRouteUpdate")

    GTMOnRouteUpdate(location)
}
