import React, { useEffect, useState, lazy } from 'react'
import { withCookies } from 'react-cookie'
import { useLocation } from '@reach/router'
import * as dayjs from 'dayjs'
import HsChat from './HsChat'

import Header from './Header'
import Footer from './Footer'

// const CookieModal = lazy(() => pMinDelay(import('./CookieModal'), 500))
const CookieModal = lazy(() => import('./CookieModal'))

import ScrollTopButton from '../assets/js/src/components/scroll-top-button'
// import smoothScroll from "../assets/js/src/components/smooth-scroll";
import parallax from '../assets/js/src/components/parallax'

// const GTM = lazy(() => pMinDelay(import('./GTM'), 4000))
const GTM = lazy(() => import('./GTM'))

// Theme CSS
import 'boxicons/css/boxicons.css'
import '../assets/scss/theme.scss'
import carousel from '../assets/js/src/components/carousel'
import HsAnalytics from './HsAnalytics'
import pMinDelay from 'p-min-delay'

export const LayoutContext = React.createContext([{}, () => {}])

export const LayoutProvider = withCookies(({ cookies, children }) => {
    const isSSR = typeof window === 'undefined'
    const [layout, setLayout] = useState({
        header: {},
        hide: false,
        cookieBannerRef: null,
        stopTrack: false,
        disableLanguageSwitcher: false,
    })

    const { header, cookieBannerRef, hide, stopTrack } = layout

    const bitaloCookie = cookies.get('bitalo') || {}
    const notrackCookie = cookies.get('notrack')

    const gtmEnabled = !!+process.env.GATSBY_ENABLE_GTM
    const hsChatEnabled = !!+process.env.GATSBY_ENABLE_HS_CHAT

    const location = useLocation()

    useEffect(() => {
        if (location.search === '?notrack') {
            cookies.set('notrack', true, {
                path: '/',
                expires: new Date(dayjs().add(12, 'M').format()),
                secure: true,
            })
        }
        ScrollTopButton()
        parallax()
        carousel() //todo: required but replace more clean with necessary only
        // smoothScroll()
    }, [])

    return (
        <LayoutContext.Provider value={[layout, setLayout]}>
            {/*Page wrapper for sticky footer*/}
            {/* Wraps everything except footer to push footer to the bottom of the page if there is little content*/}
            <main className="page-wrapper">
                {notrackCookie && !isSSR ? (
                    <div
                        className="bg-success w-100 position-fixed"
                        style={{ height: '5px', zIndex: '10000' }}
                    />
                ) : null}

                {!notrackCookie && !isSSR && !stopTrack && gtmEnabled && bitaloCookie.statistics ? (
                    <GTM location={location} />
                ) : null}

                {!notrackCookie && !isSSR && !stopTrack && bitaloCookie.statistics ? (
                    <HsAnalytics />
                ) : null}

                {!notrackCookie && !isSSR && !hide && bitaloCookie.thirdParty && hsChatEnabled ? (
                    <HsChat />
                ) : null}

                {!hide && (
                    <Header
                        className={header.className}
                        type="boxed"
                        minimal={header.minimal}
                        fixed={header.fixed}
                        initBgColor={header.bgColor}
                        initColor={header.color}
                    />
                )}

                {children}
            </main>

            {!hide && <Footer />}

            {/*Back to top button*/}
            <a href="#top" className="btn-scroll-top" data-scroll>
                <span className="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
                <i className="btn-scroll-top-icon bx bx-chevron-up"></i>
            </a>

            {!isSSR && !hide && <CookieModal ref={cookieBannerRef} />}
        </LayoutContext.Provider>
    )
})

export default LayoutContext
